import React, { useEffect, useState } from 'react'
import Typewriter from 'typewriter-effect'
import './home.scss'
import '../../components/header/header.scss'
import HAUL247BG from '../../assets/svg/bgMan.png'
import ARROWLEFT from '../../assets/images/arrow-left-cus.svg'
import ARROWRIGHT from '../../assets/images/arrow-right-cus.svg'
import EASY from '../../assets/svg/easytouse.svg'
import SUPERFAST from '../../assets/svg/superfast.svg'
import SAFE from '../../assets/svg/safe.svg'
import businessIMG from '../../assets/images/forbusinesses.jpg'
import WAREHOUSE from '../../assets/icons/services/warehouse.svg'
import TRUCK from '../../assets/icons/services/trucking.svg'
import LOGISTICS from '../../assets/icons/services/logistics.svg'
import SAFETY from '../../assets/icons/services/safety.svg'
import CHAT from '../../assets/icons/services/chat.svg'
import REPORT from '../../assets/icons/services/report.svg'
import DRIVERS from '../../assets/images/fordriver.jpg'
import PHONE from '../../assets/images/phone_image.png'
import GOOGLE from '../../assets/icons/appstore/google.svg'
// import APPLE from '../../assets/icons/appstore/apple.svg'
import BLUR from '../../assets/images/blurr.png'
import APIService from '../../utils/http'
import SEOTag from '../../components/seoTag'

const Home = () => {
    const [tonnageCount, setTonnageCount] = useState('500')

    useEffect(() => {
        getTripTonnageCount()
        return () => {
            const counters = document.querySelectorAll('.stat-result')
            const speed = 500

            counters.forEach((counter) => {
                const animate = () => {
                    const value = +counter.getAttribute('haul247stat')
                    // const units = counter.getAttribute('statunits');

                    const data = +counter.innerText

                    const time = value / speed
                    if (data < value) {
                        counter.innerText = Math.ceil(data + time)
                        setTimeout(animate, 1)
                    } else {
                        // counter.innerText = value.toLocaleString() + units;
                        counter.innerText = value.toLocaleString()
                    }
                }

                animate()
            })
        }
    }, [])

    const getTripTonnageCount = async () => {
        try {
            const { data: response } = await APIService.get('/trips-total-tonnage')
            setTonnageCount(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    // const showFaq = (e) => {

    //     const checkIfOpen = document.querySelector('.openFaq');
    //     console.log(checkIfOpen);

    //     if (checkIfOpen === null) {

    //         const target = e.target;
    //         const getParentNode = target.parentNode.parentNode
    //         getParentNode.classList.add("openFaq")

    //     } else{

    //         checkIfOpen.classList.remove('openFaq');

    //         const target = e.target;
    //         const getParentNode = target.parentNode.parentNode
    //         getParentNode.classList.add("openFaq")
    //     }

    // }

    // const hideFaq = (e) => {

    //     const target = e.target;
    //     const getParentNode = target.parentNode.parentNode
    //     getParentNode.classList.remove("openFaq")

    // }

    return (
        <>
            <SEOTag
                image=""
                title="Haul247 – Efficient Haulage Solutions | Simplified Logistics Solution for your Business"
                url="https://www.haul247.co/"
                description="Haul247 is revolutionizing Logistics using cutting edge technology. As a leading logistics technology company, we specialize in providing comprehensive solutions in haulage, warehousing, and port operations for businesses. With footprints across Nigeria, businesses can move or store goods across multiple geo-locations with ease wherever and whenever using our all-in-one logistics platform. Truck and Warehouse Owners can register their assets and get paid for its use."
            />
            <div className="home">
                <div className="landing fullpad">
                    <div className="blurr">
                        <img src={BLUR} alt="blur bg haul247" />
                    </div>

                    <div className="col-1">
                        <div className="texting">
                            <div className="text-main">
                                <h1 className="firstline">
                                    <span className="text-area"> Efficient </span>

                                    <span>
                                        <Typewriter
                                            options={{
                                                strings: ['haulage', 'warehousing', 'logistics'],
                                                autoStart: true,
                                                loop: true,
                                            }}
                                        />
                                    </span>
                                </h1>

                                <div className="remainline">solutions for Africa.</div>
                            </div>

                            <h2 className="sub_text cap-45">
                                Haul247 is the platform for businesses to seamlessly book trucks and warehouses across multiple geo-locations using real-time
                                technology.
                            </h2>
                        </div>

                        {/* <div className="inputEmail cap-45">

                            <input type="text" placeholder='Enter Email Address' />
                            <div className="getStarted">Haul With Us</div>
                            
                        </div> */}

                        <div className="statistics">
                            <div className="numbersHeading">We are so particular about numbers 🎉</div>

                            <div className="stats flex-col gap-[10px] items-start xs:items-center xs:flex-row ">
                                <div className="stat-2 stat w-full xs:w-min">
                                    <p className="stat-tag">Warehouse SQM (Square Meter) Space Available</p>

                                    <p className="stat-result mid-res" haul247stat="151000" statunits="SQM+"></p>
                                </div>

                                <div className="line-divider hidden xs:block"></div>

                                <div className="stat-2 stat w-full xs:w-min">
                                    <p className="stat-tag">Kilogram of Goods Moved</p>

                                    <p className="stat-result mid-res" haul247stat={tonnageCount * 1000} statunits="KG+"></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* BG Image Inserted in col-2 */}

                    <div className="col-2">
                        <div className="bgImage">
                            <img src={HAUL247BG} alt="logo" />
                        </div>
                    </div>
                </div>

                {/* Section-2 */}

                <div className="section-maj brand-color">
                    <div className="quickInfo fullpad">
                        <div className="arrow-left">
                            <img src={ARROWLEFT} alt="arrow-left" />
                        </div>

                        <h2 className="text-content">Freight and Warehousing needs covered with our real-time, digital and tech-enabled logistics support.</h2>

                        <div className="arrow-left">
                            <img src={ARROWRIGHT} alt="arrow-left" />
                        </div>
                    </div>

                    {/* Easy Cards */}

                    <div className="cards fullpad">
                        <div className="card">
                            <div className="icon">
                                <img src={EASY} alt="haul247" />
                            </div>
                            <h4 className="title">Easy to use</h4>
                            <p className="subtitle">
                                Navigate through the haul247 app easily as a client or partner to book warehouses & trucks alongside many more things
                            </p>
                        </div>

                        <div className="card">
                            <div className="icon">
                                <img src={SUPERFAST} alt="haul247" />
                            </div>
                            <h4 className="title">Super fast</h4>
                            <p className="subtitle">
                                Across our platforms, all logistics processes are blazing fast because of our efficient partners and digital servers that
                                anchors our work.
                            </p>
                        </div>

                        <div className="card">
                            <div className="icon">
                                <img src={SAFE} alt="haul247" />
                            </div>
                            <h4 className="title">Safe & secure</h4>
                            <p className="subtitle">
                                The safety of your shipment as a client and your assets(trucks and warehouses) as a partner, is guaranteed with us.
                            </p>
                        </div>
                    </div>

                    {/* Video Part */}

                    <div className="haul247-demo-video fullpad">
                        <div className="videoContainer">
                            <video
                                autoPlay
                                loop
                                src="https://firebasestorage.googleapis.com/v0/b/lasricv2.appspot.com/o/haul247%2Fhaul247Demo.mp4?alt=media&token=b7d7ca32-8965-42ac-af1b-9d7f913bc12d"
                            />
                        </div>
                    </div>

                    {/* Text Under Video Part */}

                    <div className="quickInfo redi">
                        <h2 className="text-content">Haulage, Warehousing and Port Operation covered in a single system</h2>

                        <p>{'////////'}</p>
                    </div>
                </div>

                <div className="section-user-class fullpad pad-2x">
                    <div className="image-user">
                        <img src={businessIMG} alt="haul247 Individual" />
                    </div>

                    <div className="text-part">
                        <div className="for-who">For Businesses</div>

                        <h3 className="main">
                            Running a <span className="underline yellow">business</span> has so many moving parts, lets take <span>logistics</span> off the
                            chain.
                        </h3>

                        <p className="subtitle">
                            Whether you are an individual, farmer, FMCG or manufacturing company, Haul247 will complement your supply chain process by providing
                            movement and storage of your goods as well as providing valuable insights on tracking.
                        </p>

                        <div className="buttonParts">
                            <a href="#contact_sales" className="contact_sales outline">
                                Contact Us
                            </a>
                            <a href="https://client.haul247.co/login" className="contact_sales">
                                Sign Up
                            </a>
                        </div>
                    </div>
                </div>

                {/* services summary */}

                <div className="services_summary fullpad brand-color">
                    <div className="title_tag">our service offerings</div>

                    <h2 className="mainText">
                        We are committed to simplifying haulage solutions for africa with value driven services & 99.9% service uptime.
                    </h2>

                    <div className="cards">
                        <div className="card">
                            <div className="icon">
                                <img src={WAREHOUSE} alt="haul247" />{' '}
                                <a href="https://client.haul247.co/login" className="actionBTN w">
                                    {' '}
                                    Book Now{' '}
                                </a>{' '}
                            </div>
                            <h3 className="title">Warehousing</h3>
                            <p className="subtitle">
                                Store those goods either as part of a trip or a stand-alone request? Request across multiple geo-locations from wherever you are
                                for your cargo.
                            </p>
                        </div>

                        <div className="card">
                            <div className="icon">
                                <img src={TRUCK} alt="haul247" />{' '}
                                <a href="https://client.haul247.co/login" className="actionBTN t">
                                    Sign Up
                                </a>
                            </div>
                            <h3 className="title">Truck Services</h3>
                            <p className="subtitle">Optimize your truck services for route, safety and travel time.</p>
                        </div>

                        <div className="card">
                            <div className="icon">
                                <img src={LOGISTICS} alt="haul247" />{' '}
                            </div>
                            <h3 className="title">Real-time logistics tracking </h3>
                            <p className="subtitle">
                                Right on spot, book any of our listed well-equipped warehouses across multiple geo-locations from wherever you are for your
                                cargo.
                            </p>
                        </div>

                        <div className="card">
                            <div className="icon">
                                <img src={SAFETY} alt="haul247" />
                            </div>
                            <h3 className="title">Safety & insurance </h3>
                            <p className="subtitle">
                                No one wants to worry about a missing shipment, yeah? Our insurance policy covers your shipment from origin to destination, so
                                no worries 😉
                            </p>
                        </div>

                        <div className="card">
                            <div className="icon">
                                <img src={CHAT} alt="haul247" />
                                <a href="#contact_sales" className="actionBTN c">
                                    {' '}
                                    Contact Support{' '}
                                </a>
                            </div>
                            <h3 className="title">Talk to us whenever</h3>
                            <p className="subtitle">
                                Our dedicated customer-centric team is always ready to attend to all inquiries and provide needed support with our fast-paced
                                digital servers.📞
                            </p>
                        </div>

                        <div className="card">
                            <div className="icon">
                                <img src={REPORT} alt="haul247" />
                            </div>
                            <h3 className="title">Seamless reporting </h3>
                            <p className="subtitle">
                                View more insights to top customers, routes, insights to your assets utilization as well as other efficiency stats. Readily
                                available views. 📈
                            </p>
                        </div>
                    </div>
                </div>

                {/* for drivers api */}

                <div className="section-user-class fullpad pad-2x">
                    <div className="text-part">
                        <div className="for-who">Become a Partner</div>

                        <h2 className="main">
                            Are you a <span className="underline">warehouse owner</span> or <span className="underline">truck owner</span>?
                        </h2>

                        <div className="tiny">And you want to - </div>

                        <div className="subtitle marg">
                            <div className="points">
                                <div className="check_icon">
                                    <i className="fi fi-rr-check"></i>
                                </div>
                                <p>Pick up more packages / deliverables than ever?</p>
                            </div>

                            <div className="points">
                                <div className="check_icon">
                                    <i className="fi fi-rr-check"></i>
                                </div>
                                <p>Connect with haul247 partners for delivery services?</p>
                            </div>

                            <div className="points">
                                <div className="check_icon">
                                    <i className="fi fi-rr-check"></i>
                                </div>
                                <p>Use your trucks to make extra cash for yourselves?</p>
                            </div>

                            <div className="points">
                                <div className="check_icon">
                                    <i className="fi fi-rr-check"></i>
                                </div>
                                <p>Earn more money on your terms?</p>
                            </div>
                        </div>

                        <div className="buttonParts">
                            <a href="#contact_sales" className="contact_sales outline">
                                Contact Us
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.haul247" target="_blank" className="contact_sales" rel="noreferrer">
                                Get Started - Download Partner App
                            </a>
                        </div>
                    </div>

                    <div className="image-user">
                        <img src={DRIVERS} alt="haul247 Individual" />
                    </div>
                </div>

                <div className="fullpad brand-color downloadArea">
                    <div className="phone_holder">
                        <img src={PHONE} alt="haul247" />
                    </div>

                    <div className="steps_manage">
                        <h2 className="mainText">Earn more with your assets - seamless management in the palm of your hands</h2>

                        <div className="tiny tink">Get started in four (4) steps</div>

                        <div className="steps">
                            <div className="linethru"></div>

                            <div className="step">
                                <div className="tap">Step 1</div>
                                <div className="lineClear"></div>
                                <p>Download the mobile app from playstore to get started.</p>
                            </div>

                            <div className="step">
                                <div className="tap">Step 2</div>
                                <div className="lineClear cleb"></div>
                                <p>Register with your valid details and set up your account with all the necessary information needed.</p>
                            </div>

                            <div className="step">
                                <div className="tap">Step 3</div>
                                <div className="lineClear"></div>
                                <p>List your assets on the app for validation.</p>
                            </div>

                            <div className="step">
                                <div className="tap">Step 4</div>
                                <div className="lineClear"></div>
                                <p>Sit back and earn big</p>
                            </div>
                        </div>

                        <div className="downloadIcons">
                            <a href="https://play.google.com/store/apps/details?id=com.haul247" target="_blank" className="store" rel="noreferrer">
                                <img src={GOOGLE} alt="stores haul247" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home

import React from 'react'
import { Link } from 'react-router-dom'

const Pages = (props) => {
    return (
        <section className="bg-[#FFFFF7] body-padding py-[85px]">
            <div>
                <h3 className="font-semibold text-[26px] leading-[38px] font-inter-md">Move Your Supply Chain A Step Further</h3>
                <div className={`grid grid-cols-auto ${props.grid ? props.grid : 'md:grid-cols-3'} gap-9 lg:gap-[76px] mt-[57px]`}>
                    {props.pages.map((el, idx) => (
                        <div className="flex flex-col h-fit lg:h-[264px]" key={idx + 1}>
                            <div className="w-[45px] h-[45px]">
                                <img src={el.icon} alt="icon" />
                            </div>

                            <h4 className="mt-5 font-medium text-lg mb-[15px]">{el.title}</h4>
                            <p className="text-[#777777] text-[15px] leading-[27px]">{el.description}</p>

                            <Link to={el.link} className="text-[#F07B22] font-inter-md font-semibold mt-2 lg:mt-auto border-b-2 w-[88px] border-[#F07B22]">
                                Learn more
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Pages

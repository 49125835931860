import React, { useState } from 'react'
import CONTACT from '../../assets/images/contact.jpg'

import apple from '../../assets/logos/clients/apple.svg'
import comart from '../../assets/logos/clients/cormart.svg'
import cway from '../../assets/logos/clients/cway.svg'
import gb from '../../assets/logos/clients/gb.svg'
import honey from '../../assets/logos/clients/honey.svg'
import pam from '../../assets/logos/clients/pam.svg'
import prom from '../../assets/logos/clients/prom.svg'
import pz from '../../assets/logos/clients/pz.svg'
import reckit from '../../assets/logos/clients/reckit.svg'
import tgi from '../../assets/logos/clients/tgi.svg'
import unilever from '../../assets/logos/clients/unilever.svg'
import knauf from '../../assets/logos/clients/knauf.svg'
import APIService from '../../utils/http'
import Faq from '../ui/faq'

const ExtraHome = (props) => {
    const [loading, setLoading] = useState(false)

    // const showFaq = (e) => {
    //     const checkIfOpen = document.querySelector('.openFaq')

    //     if (checkIfOpen === null) {
    //         const target = e.target
    //         const getParentNode = target.parentNode.parentNode
    //         getParentNode.classList.add('openFaq')
    //     } else {
    //         checkIfOpen.classList.remove('openFaq')

    //         const target = e.target
    //         const getParentNode = target.parentNode.parentNode
    //         getParentNode.classList.add('openFaq')
    //     }
    // }

    // const hideFaq = (e) => {
    //     const target = e.target
    //     const getParentNode = target.parentNode.parentNode
    //     getParentNode.classList.remove('openFaq')
    // }

    const submitForm = async (event) => {
        setLoading(true)
        event.preventDefault()
        const form = document.querySelector('.contactForm')
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)

        try {
            await APIService.post('/contact', data)
        } catch (error) {}

        window.dataLayer.push({
            event: 'contact_form_submitted',
        })
        setLoading(false)
        form.reset()
    }

    return (
        <div>
            {/* What Customers Are Saying. */}

            <div className="customerSays fullpad pad-2x">
                <div className="forWho">Testimonials</div>

                <div className="mainText">
                    Don’t just take our word for it, <span>see for yourself.</span>
                </div>

                <div className="reviews">
                    <div className="review">
                        <p>
                            “Working with Haul247 has provided great insight to how well I can organise my company’s haulage needs. I can manage, monitor and
                            see all my trips in one place. It’s amazing to see an app that can help do both haulage and warehousing seamlessly. Haul247 also
                            puts a premium on safety of our goods and the customer service is great."
                        </p>

                        <div className="identity">
                            <div className="icon"> TA </div>
                            <div className="namePage">
                                <div className="realname">Tosin Ajayi</div>
                                <div className="handle">GB Foods Logistics Manager</div>
                            </div>
                        </div>
                    </div>

                    <div className="review">
                        <p>
                            “Before Haul247 I could barely get enough trips to maximise my trucks usage. Partnering with Haul247 has helped increase my trucks
                            usage has increased significantly helping to make us profitable. We do over 70% more trips than we used to do on our own. Kudos!
                            Haul247 looking forward to doing more business!"
                        </p>

                        <div className="identity">
                            <div className="icon"> DO </div>
                            <div className="namePage">
                                <div className="realname">Dike Okechukwu</div>
                                <div className="handle">Okeymore Global Limited, Truck owner</div>
                            </div>
                        </div>
                    </div>

                    <div className="review">
                        <p>
                            “We have used different haulage partners in the past but we normally have to work with so many multiple partners to move and
                            warehouse our goods. With Haul247 this has become easier for us because they do both effectively. We can easily make our trips and
                            ware house requests on our dashboard and the system does the rest. "
                        </p>

                        <div className="identity">
                            <div className="icon"> KM </div>
                            <div className="namePage">
                                <div className="realname"> Kareem Maji </div>
                                <div className="handle"> Transport Manager, TGI </div>
                            </div>
                        </div>
                    </div>

                    <div className="review">
                        <p>
                            “Haul247 makes it easy to generate revenue for our idle assets. My company has a few warehouses in various locations in states
                            outside Lagos with minimal exposure. Partnering with Haul247 has enabled us activate and increase usage of our warehouses, we can
                            see orders on our app and effectively meet those requests."
                        </p>

                        <div className="identity">
                            <div className="icon"> FJ </div>
                            <div className="namePage">
                                <div className="realname"> Femi Jibowo </div>
                                <div className="handle"> Jibxie-ONC Concepts Logistics </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="clientsZone">
                    <h5>Trusted by leading brands</h5>

                    <div className="clients">
                        <div className="client">
                            <img src={apple} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={pam} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={pz} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={cway} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={tgi} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={unilever} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={reckit} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={knauf} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={prom} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={honey} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={gb} alt="Haul247 Clients" />
                        </div>

                        <div className="client">
                            <img src={comart} alt="Haul247 Clients" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-[#fafaf7] text-black">
                <Faq
                    faqs={[
                        {
                            question: 'What services does Haul247 offer?',
                            answer: `Haul247 offers a comprehensive range of services including transportation (road, rail, air, sea), warehousing, distribution, inventory management, customs clearance, and supply chain optimisation.`,
                        },
                        {
                            question: 'What modes of transportation do you utilize?',
                            answer: `We utilize a range of transportation modes including trucks to ensure the most efficient delivery based on client requirements.`,
                        },
                        {
                            question: 'How do you determine pricing for your services?',
                            answer: `Our pricing is determined based on factors such as distance, mode of transportation, volume/weight, and specific client requirements.`,
                        },
                        {
                            question: `What types of goods do you transport?`,
                            answer: `We handle a diverse range of goods, including perishable items, machinery, consumer goods, and more.`,
                        },
                        {
                            question: `What types of storage solutions do you offer?`,
                            answer: `Our warehouses cater to various needs, including ambient, temperature-controlled, and secure storage options.`,
                        },

                        {
                            question: `What logistics solutions do you provide?`,
                            answer: `We offer end-to-end logistics solutions, including supply chain management, distribution, haulage and warehousing.`,
                        },
                        {
                            question: `How does your platform work for truck owners?`,
                            answer: `Our platform provides a marketplace where truck owners can lease their vehicles to businesses in need of transportation services.`,
                        },
                    ]}
                />
            </div>

            {/* Contact Section */}

            <div id="contact_sales" className="contactPage fullpad pad-3x">
                <div className="photoOp">
                    <img src={CONTACT} alt="person" />
                </div>

                <div className="contact">
                    <div className="tiny">If you need help or have a question, we’re here for you</div>

                    <div className="mainText">Get in touch with us for more information.</div>

                    <form className="contactForm" onSubmit={submitForm}>
                        <div className="fields">
                            <div className="field">
                                <label> Firstname </label>
                                <input name="first_name" type="text" placeholder="Enter Name" required autoComplete="none" />
                            </div>

                            <div className="field">
                                <label> Lastname </label>
                                <input name="last_name" type="text" placeholder="Enter Name" required autoComplete="none" />
                            </div>
                        </div>

                        <div className="fields">
                            <div className="field">
                                <label> Email Address </label>
                                <input name="email" type="email" placeholder="@" required autoComplete="none" />
                            </div>

                            <div className="field">
                                <label> Phone </label>
                                <input name="phone_number" type="number" placeholder="+234" required min={11} autoComplete="none" />
                            </div>
                        </div>

                        <div className="fields">
                            <div className="field width-100">
                                <label> Your Message </label>
                                <textarea name="message" id="" placeholder="Write your message..." required></textarea>
                            </div>
                        </div>

                        <button type="submit" className="button_send_message">
                            {loading ? <span className="loading-circle   "></span> : <span>Send Message</span>}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ExtraHome

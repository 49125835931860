import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from '../pages/home/home'
import About from '../pages/about/about'
// import Business from '../pages/services/business'
// import Partner from '../pages/services/partner'
import Impact from '../pages/impact/impact'
import Careers from '../pages/careers/careers'
import Haulage from '../pages/products/haulage'
import PageLayout from './pageLayout'
import Warehousing from '../pages/products/warehousing'
import PortOperations from '../pages/products/portOperations'
import SupplyChain from '../pages/products/supplyChain'
import RawMaterial from '../pages/products/rawMaterials'
import { HeaderLayout } from './pageLayout'
import Tracking from '../pages/solutions/tracking'
import Insurance from '../pages/solutions/insurance'
import Delivery from '../pages/solutions/delivery'
import ReverseLogistics from '../pages/solutions/reverseLogistics'
import Blogs from '../pages/resources/blogs/blogs'
import BlogPost from '../pages/resources/blogs/blogPost'
import EGuide from '../pages/resources/eGuide/eGuide'
import Videos from '../pages/resources/videos'
import Guide from '../pages/resources/eGuide/guide'
import Glossary from '../pages/resources/glossary'
import GlossarySubject from '../pages/resources/glossary/glossarySubject'
import CaseStudy from '../pages/resources/caseStudy'
import Video from '../pages/resources/videos/video'
import Faq from '../pages/resources/faq'

const RouterClass = () => {
    return (
        <Routes>
            <Route path="/" element={<PageLayout component={<Home />} />} />
            <Route path="/about" element={<PageLayout component={<About />} />} />
            <Route path="/impact" element={<PageLayout component={<Impact />} />} />
            <Route path="/services">
                <Route index element={<Navigate to="/" replace />} />
                <Route path="business" element={<Navigate to="/" replace />} />
                <Route path="partner" element={<Navigate to="/" replace />} />
            </Route>
            <Route path="/resources">
                <Route index element={<HeaderLayout component={<Blogs />} />} />
                <Route path="blog" element={<HeaderLayout component={<Blogs />} />} />
                <Route path="blog/:slug" element={<HeaderLayout component={<BlogPost />} />} />
                <Route path="e-guides" element={<HeaderLayout component={<EGuide />} />} />
                <Route path="videos" element={<HeaderLayout component={<Videos />} />} />
                <Route path="video/:id" element={<HeaderLayout component={<Video />} />} />
                <Route path="e-guides/:slug" element={<HeaderLayout component={<Guide />} />} />
                <Route path="glossary" element={<HeaderLayout component={<Glossary />} />} />
                <Route path="glossary/:section/:slug" element={<HeaderLayout component={<GlossarySubject />} />} />
                <Route path="case-studies" element={<HeaderLayout component={<CaseStudy />} />} />
                <Route path="faq" element={<HeaderLayout component={<Faq />} />} />
            </Route>
            <Route path="/products">
                <Route index element={<HeaderLayout component={<Haulage />} />} />
                <Route path="haulage" element={<HeaderLayout component={<Haulage />} />} />
                <Route path="warehousing" element={<HeaderLayout component={<Warehousing />} />} />
                <Route path="port-operations" element={<HeaderLayout component={<PortOperations />} />} />
                <Route path="supply-chain" element={<HeaderLayout component={<SupplyChain />} />} />
                <Route path="raw-material" element={<HeaderLayout component={<RawMaterial />} />} />
            </Route>
            <Route path="/solutions">
                <Route index element={<HeaderLayout component={<Tracking />} />} />
                <Route path="tracking" element={<HeaderLayout component={<Tracking />} />} />
                <Route path="insurance" element={<HeaderLayout component={<Insurance />} />} />
                <Route path="delivery" element={<HeaderLayout component={<Delivery />} />} />
                <Route path="reverse-logistics" element={<HeaderLayout component={<ReverseLogistics />} />} />
            </Route>
            <Route path="/careers" element={<PageLayout component={<Careers />} />} />

            <Route path="*" element={<PageLayout component={<Home />} />} />
        </Routes>
    )
}

export default RouterClass

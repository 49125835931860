import React from 'react'
import Header from '../components/header/header'
import Footer from '../components/footer/footer'

const PageLayout = ({ component, showClients }) => {
    return (
        <>
            <Header />
            {component}
            <Footer showClients={showClients} />
        </>
    )
}

export default PageLayout

export const HeaderLayout = ({ component }) => {
    return (
        <>
            <Header />
            {component}
        </>
    )
}

import React from 'react'
import PortIcon from '../../assets/icons/port.svg'
import SupplyIcon from '../../assets/icons/supplyChain.svg'
import Hero from '../../components/ui/hero'
import BenefitSection from '../../components/ui/benefitSection'
import Brands from '../../components/ui/brands'
import Pages from '../../components/ui/pages'
import StatsCount from '../../components/ui/statsCount'
import TruckIcon from '../../assets/icons/benefits/trucks.svg'
import CostOptimizationIcon from '../../assets/icons/benefits/costOptimization.svg'
import CareIcon from '../../assets/icons/benefits/care.svg'
import TailorIcon from '../../assets/icons/benefits/tailor.svg'
import ReliableIcon from '../../assets/icons/benefits/reliabe.svg'
import SustainIcon from '../../assets/icons/benefits/sustain.svg'
import WarehouseIcon from '../../assets/icons/warehouse.svg'
import WheelBarrow from '../../assets/icons/wheelBarrow.svg'
import Faq from '../../components/ui/faq'
import ContactForm from '../../components/ui/contactForm'
import Footer from '../../components/ui/layout/footer'
import Cta from '../../components/ui/cta'
import SEOTag from '../../components/seoTag'

const RawMaterial = () => {
    return (
        <>
            <SEOTag image="" title="Haul247 | Raw Materials Shipping " url="https://www.haul247.co/products/raw-material" description="" />

            <div className="font-inter text-[#242424]">
                <Hero
                    icon={WheelBarrow}
                    heading={'Fuelling Innovation, Delivering Excellence'}
                    subHeading={`We are redefining the trajectory of transportation with our Haulage solutions. SeamlesHaul247 solutions ensure seamless transportation of your raw materials, empowering your supply chain with precision and reliability.`}
                    buttonText={'Get Started'}
                    link={'/contact'}
                />

                <BenefitSection
                    benefits={[
                        {
                            heading: 'Precision with Care',
                            subText: `From handling fragile materials to managing high-volume shipments, our trained professionals ensure the utmost care at every step of the transportation process.`,
                            icon: CareIcon,
                        },
                        {
                            heading: 'Tailored Solutions',
                            subText: `We understand the unique demands of different industries. Whether it's bulk commodities or specialized materials, our customizable solutions cater to your specific needs.`,
                            icon: TailorIcon,
                        },
                        {
                            heading: 'Reliability',
                            subText: `We specialize in precision-timed deliveries, ensuring your raw materials reach their destination exactly when you need them. Our commitment to reliability means you can count on us for seamless operations.`,
                            icon: ReliableIcon,
                        },
                        {
                            heading: 'Sustainability Initiatives',
                            subText: `Embrace a greener future with our commitment to sustainable logistics practices. We offer eco-friendly shipping solutions that reduce your carbon footprint without sacrificing delivery speed or reliability.`,
                            icon: SustainIcon,
                        },
                        {
                            heading: 'Cost Optimization',
                            subText: `Say goodbye to unnecessary expenses. Our innovative approach identifies cost-saving opportunities within your logistics operations, ensuring maximum efficiency without compromising quality.`,
                            icon: CostOptimizationIcon,
                        },
                    ]}
                />

                <Brands />

                <StatsCount
                    stats={[
                        {
                            number: 20,
                            text: `Cost Reduction in logistics costs without compromising on the quality or timeliness of our raw material deliveries which has directly impacted the bottom line of our clients in a positive way.`,
                            active: false,
                        },
                        {
                            number: 20,
                            text: `Environmental Impact Reduced by 20%. Through their eco-friendly shipping solutions, we managed to reduce our carbon footprint significantly.`,
                            active: true,
                        },
                        {
                            number: 30,
                            text: `Increase in On-Time Deliveries thereby boosting the production efficiency of our clients by ensuring a steady and reliable supply chain.`,
                            active: false,
                        },
                    ]}
                />

                <Pages
                    grid="lg:grid-cols-4"
                    pages={[
                        {
                            title: 'Warehousing',
                            description:
                                'It’s not just about storing goods, it’s about maximizing every inch of space efficiently without compromising accessibility.',
                            link: '/products/warehousing',
                            icon: WarehouseIcon,
                        },
                        {
                            title: 'Port Operations',
                            description:
                                'From documentation to efficient cargo movement, trust us to navigate ports effortlessly. We handle the complexities while you focus on your business growth.',
                            link: '/products/port-operations',
                            icon: PortIcon,
                        },
                        {
                            title: 'Supply Chain Optimization',
                            description: 'We analyze and strategize and implement tailored solutions for efficiency and cost-effectiveness.',
                            link: '/products/supply-chain',
                            icon: SupplyIcon,
                        },
                        {
                            title: 'Haulage Solutions',
                            description: `Trust us to deliver your cargo swiftly, securely, and with precision, ensuring it reaches its destination on time, every time.`,
                            link: '/products/haulage',
                            icon: TruckIcon,
                        },
                    ]}
                />

                <div className="font-br-firma">
                    <div className="bg-[#FFFFF7]">
                        <Faq
                            faqs={[
                                {
                                    question: 'What types of raw materials does your company specialize in shipping?',
                                    answer: ``,
                                },
                                {
                                    question: 'Can you handle both domestic and international shipments?',
                                    answer: `Yes, our logistics solutions cater to both domestic and international shipments. We have the expertise and global network to facilitate smooth transportation across borders.`,
                                },
                                { question: 'How do you ensure the safety and security of raw materials during transit?', answer: `` },
                                { question: `How can we get started with your logistics services?`, answer: `` },
                            ]}
                        />
                    </div>

                    <Cta
                        pillText={'READY TO GET STARTED'}
                        ctaHeading={'Unlock reliability, efficiency, and coat-saving benefits.'}
                        ctaButton={'Ship Smarter'}
                    />

                    <ContactForm />

                    <Footer />
                </div>
            </div>
        </>
    )
}

export default RawMaterial

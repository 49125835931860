import React, { useRef, useEffect } from 'react'
import apple from '../../assets/logos/clients/apple.svg'
import comart from '../../assets/logos/clients/cormart.svg'
import gb from '../../assets/logos/clients/gb.svg'
import prom from '../../assets/logos/clients/prom.svg'
import pz from '../../assets/logos/clients/pz.svg'
import tgi from '../../assets/logos/clients/tgi.svg'
import unilever from '../../assets/logos/clients/unilever.svg'
import premier_fill from '../../assets/logos/clients/premier_fill.svg'
import premium_edible from '../../assets/logos/clients/premium_edible.svg'
import hayat from '../../assets/logos/clients/hayat.svg'
import fmn from '../../assets/logos/clients/fmn.svg'
import nb from '../../assets/logos/clients/nb.svg'
import olam from '../../assets/logos/clients/olam.svg'
import panda from '../../assets/logos/clients/panda.svg'
import knight from '../../assets/logos/clients/knightB.svg'

const LogoCarousel = () => {
    const clients = [gb, tgi, prom, premier_fill, premium_edible, pz, hayat, apple, fmn, nb, olam, unilever, comart, panda, knight]

    const logoSlideRef = useRef(null)
    const logoCarouselRef = useRef(null)

    useEffect(() => {
        // if (logoSlideRef.current && logoCarouselRef.current) {
        //     var copy = logoSlideRef.current.cloneNode(true)
        //     logoSlideRef.current.parentNode.appendChild(copy)
        // }
        var copy = document.querySelector('.logos-slide').cloneNode(true)
        document.querySelector('.logo-carousel').appendChild(copy)
    }, [])

    return (
        <div>
            <h5 className="mx-6 md:mx-[150px] border-b border-b-[#EEEEEE] pb-5 md:pb-10">Trusted by leading brands</h5>
            <div className="logo-carousel" ref={logoCarouselRef}>
                <div className="logos-slide" ref={logoSlideRef}>
                    {clients.map((client, index) => (
                        <img src={client} key={index + 1} loading="lazy" alt="client logo" className="h-[50px] mx-5 md:mx-10 !inline" />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default LogoCarousel

import React from 'react'
import ApplePear from '../../assets/images/brands/applePear.svg'
import PZ from '../../assets/images/brands/pz.svg'
import Pandagric from '../../assets/images/brands/pandagric.svg'
import Reckit from '../../assets/images/brands/reckit.svg'
import TGI from '../../assets/images/brands/tgi.svg'
import Unilever from '../../assets/images/brands/unilever.svg'
import Knauf from '../../assets/images/brands/knauf.svg'
import Cormart from '../../assets/images/brands/cormart.svg'
import Prom from '../../assets/images/brands/prom.svg'
import Honey from '../../assets/images/brands/honey.svg'
import GB from '../../assets/images/brands/gb.svg'

const Brands = () => {
    const brands = [ApplePear, PZ, Pandagric, Reckit, TGI, Unilever, Knauf, Cormart, Prom, Honey, GB]

    return (
        <section className="body-padding pt-5 pb-9  bg-[#FAFAFA] mt-[100px]">
            <div>
                <h6 className="text-[#1C4863] font-semibold font-inter-md mb-10">Join Leading Brands On Haul247</h6>
                <div className="grid grid-cols-4 md:grid-cols-11 gap-10">
                    {brands.map((el, idx) => (
                        <div key={idx + 1}>
                            <img src={el} alt="brands" />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Brands

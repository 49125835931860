import React from 'react'
import WARE from '../../assets/images/ware.jpg'
import TRUCK from '../../assets/images/truck.jpg'

const TabComp = ({ tab }) => {
    return (
        <div className="tabComponent">
            {tab === 'warehouse' ? (
                <div className="service_tab_headers">
                    <div className="goodContent">
                        <h1 className="h1">Quality and secure warehousing services.</h1>

                        <p className="point">
                            {' '}
                            Store those goods either as part of a trip or a stand-alone request. Request across multiple geo-locations from wherever you are for
                            your cargo.
                        </p>

                        <div className="content_service">
                            <div className="subtitle marg">
                                <div className="points">
                                    <div className="check_icon">
                                        <i className="fi fi-rr-check"></i>
                                    </div>
                                    <p>Search and book warehouses for your goods from wherever you are using a locator tool powered by automated softwares.</p>
                                </div>

                                <div className="points">
                                    <div className="check_icon">
                                        <i className="fi fi-rr-check"></i>
                                    </div>
                                    <p>Finalized quotes and seamless payment pathway on choice of warehouse facilities </p>
                                </div>

                                <div className="points">
                                    <div className="check_icon">
                                        <i className="fi fi-rr-check"></i>
                                    </div>
                                    <p>Wide-range options of warehouse spaces across multiple geo-locations across Africa</p>
                                </div>

                                <div className="points">
                                    <div className="check_icon">
                                        <i className="fi fi-rr-check"></i>
                                    </div>
                                    <p>Top tier security is guaranteed at our warehouse facilities</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="image_Service">
                        <img src={WARE} alt="warehousing" />
                    </div>
                </div>
            ) : (
                <div className="service_tab_headers">
                    <div className="goodContent">
                        <h1 className="h1">Efficient Haulage (Truck) Services for your business.</h1>

                        <p className="point">
                            {' '}
                            Store those goods either as part of a trip or a stand-alone request. Request across multiple geo-locations from wherever you are for
                            your cargo.
                        </p>

                        <div className="content_service">
                            <div className="subtitle marg">
                                <div className="points">
                                    <div className="check_icon">
                                        <i className="fi fi-rr-check"></i>
                                    </div>
                                    <p>Search and book warehouses for your goods from wherever you are using a locator tool powered by automated softwares.</p>
                                </div>

                                <div className="points">
                                    <div className="check_icon">
                                        <i className="fi fi-rr-check"></i>
                                    </div>
                                    <p>Finalized quotes and seamless payment pathway on choice of warehouse facilities </p>
                                </div>

                                <div className="points">
                                    <div className="check_icon">
                                        <i className="fi fi-rr-check"></i>
                                    </div>
                                    <p>Wide-range options of warehouse spaces across multiple geo-locations across Africa</p>
                                </div>

                                <div className="points">
                                    <div className="check_icon">
                                        <i className="fi fi-rr-check"></i>
                                    </div>
                                    <p>Top tier security is guaranteed at our warehouse facilities</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="image_Service">
                        <img src={TRUCK} alt="trucking" />
                    </div>
                </div>
            )}
        </div>
    )
}

export default TabComp

/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";

function useIsMobile(breakpoint = 768) {
	const getWidth = () => (window.innerWidth > 0 ? window.innerWidth : screen.width);

	const [isMobile, setIsMobile] = useState(() => getWidth() <= breakpoint);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(getWidth() <= breakpoint);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [breakpoint]);

	return isMobile;
}

export default useIsMobile;

import React from 'react'

const MarketingForm = ({ classNames, inputRef }) => {
    function onSubmitForm(event) {
        event.preventDefault()
    }

    return (
        <form
            onSubmit={onSubmitForm}
            className={`${classNames || ''} bg-[#242424] font-inter text-white px-6 md:px-8  lg:px-[80px] py-12  lg:pt-[110px] overflow-y-auto`}
        >
            <h2 className="font-bold text-2xl md:text-4xl font-inter-bold">Get in touch with us for more information.</h2>

            <div id="marketing_form" className="grid gap-y-10  mt-12">
                <Input inputRef={inputRef} name={'first_name'} placeholder={'Enter Name'} label={'Name'} />
                <Input name={'company'} placeholder={'Enter Company'} label={'Company'} />
                <Input name={'email'} placeholder={'Enter Email Address'} label={'Email'} type="email" />
                <div>
                    <label className="block mb-2 text-sm font-medium text-[#4D4D4D]" htmlFor="options">
                        What service would you be interested in?
                    </label>
                    <select
                        className=" border-b border-[#e5e5e5] text-[#e5e5e5] text-sm focus:ring-[#1C4863] focus:border-[#1C4863] block w-full py-2.5  placeholder-gray-400"
                        name="options"
                        id="options"
                    >
                        <option value="" className="placeholder-gray-400 text-gray-400 text-sm  disabled:text-white">
                            Select options
                        </option>

                        <option value="trucking">Haulage/Trucking</option>
                        <option value="Warehousing">Warehousing</option>
                        <option value="Port Operations">Port Operations</option>
                        <option value="Supply Chain Management">Supply Chain Management</option>
                        <option value="Raw Materials Shipping">Raw Materials Shipping</option>
                        <option value="General Logistics">General Logistics</option>
                    </select>
                </div>
            </div>

            <button className="btn-haul mt-10">Submit</button>
        </form>
    )
}

const Input = ({ inputRef, label, type = 'text', placeholder, name }) => {
    return (
        <div>
            <label className="block mb-2 text-sm font-medium text-[#4D4D4D]" htmlFor={name}>
                {label}
            </label>
            <input
                name={name}
                ref={inputRef}
                id={name}
                type={type}
                placeholder={placeholder}
                required
                className="shadow-none rounded-none ring-0 outline-none border-b border-b-[#e5e5e5] text-[#e5e5e5] text-sm  focus:border-b-[#1C4863] block w-full py-2.5  placeholder-gray-400"
            />
        </div>
    )
}

export default MarketingForm

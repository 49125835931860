import React from 'react'
import './footer.scss'
import ExtraHome from './extraHome'
import FooterUi from '../ui/layout/footer'

const Footer = ({ showClients = true }) => {
    return (
        <div className="footer">
            <ExtraHome showClients={showClients} />

            <FooterUi />
        </div>
    )
}

export default Footer

import React from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'

const PostCard = ({ post, width, baseUrl, buttonText }) => {
    const matches = useMediaQuery('(min-width: 768px)')
    return (
        <div
            className="h-[404px]"
            style={{
                width: matches && width ? width : '',
            }}
        >
            <div className="md:w-[386px] h-[212px] bg-slate-500">
                <img src={post?.image} alt="blogImg" className="object-cover w-full h-full" />
            </div>
            <div className="mt-5 mb-[30px]">
                {post?.date && <p className="text-[#777777] text-sm font-semibold">{dayjs(post?.date).format('MMMM D, YYYY')}</p>}
                <h3 className="mt-5 md:h-12 font-semibold">{post?.title}</h3>
            </div>
            <Link
                to={`${baseUrl}${post?.slug}`}
                className="border border-[#999999] h-[46px] rounded-md text-sm px-11 py-3 hover:bg-slate-300 hover:border-opacity-0 hover:text-white"
            >
                {buttonText || 'Read More'}
            </Link>
        </div>
    )
}

export default PostCard

import React from 'react'
import BenefitSection from '../../components/ui/benefitSection'
import ThumbsIcon from '../../assets/icons/benefits/thumbs.svg'
import truck from '../../assets/icons/benefits/truckfast.svg'
import box from '../../assets/icons/benefits/shipBox.svg'
import logo from '../../assets/logos/247-logo.svg'
import './style.scss'
import cargoImg from '../../assets/images/cargo.svg'
import { _getInitials } from '../../utils/helper'
import MarketingForm from './marketing_form'
import useIsMobile from '../../hooks/useIsMobile.js'
import { Link } from 'react-router-dom'

const Marketing = () => {
    const isMobile = useIsMobile()
    let textInput = null

    function handleGetStartedButton() {
        if (!isMobile) {
            if (textInput) {
                textInput.focus()
            }
        } else {
            const contactSection = document.getElementById('marketing_form')
            contactSection.scrollIntoView({ behavior: 'smooth' })
        }
    }
    return (
        <div className=" text-[#242424] marketing">
            <div className="grid grid-cols-1 lg:grid-cols-2">
                <div>
                    <div id="hero-section" className="px-6 md:px-8  font-inter  md:pl-[80px] py-8 ">
                        <Link to="/" className="mb-[50px] block">
                            <img src={logo} alt="logo" />
                        </Link>

                        <div className="w-full xl:w-[530px] mb-[30px]">
                            <h1 className="font-bold text-2xl md:text-4xl font-inter-bold mb-[30px] break-words">
                                Reliable logistics solutions for all scales of business
                            </h1>
                            <p className="text-[#333333] text-sm leading-6 font-medium ">
                                We are redefining logistics management through cutting-edge technology. As a leading B2B logistics solutions provider, we
                                specialise in haulage, warehousing, and port operations, offering seamless services tailored to meet the unique needs of your
                                business.
                            </p>
                        </div>
                        <button onClick={handleGetStartedButton} className="btn-haul">
                            Get Started
                        </button>
                    </div>

                    <BenefitSection
                        benefits={[
                            {
                                heading: 'Fast and Precise Deliveries',
                                subText: `At haul247, we pride ourselves on our ability to deliver your packages quickly and accurately. Our efficient logistics system ensures that your shipments arrive on time, every time.`,
                                icon: truck,
                            },
                            {
                                heading: 'Ship and Track with Ease',
                                subText: ` With haul247, you can easily ship your packages and track them in real-time. Our user-friendly platform allows you to monitor the progress of your shipments from pick-up to delivery.`,
                                icon: box,
                            },
                            {
                                heading: 'Reliable Logistics Partner',
                                subText: `Whether you're a small business or a large corporation, haul247 is your go-to logistics partner. Our dedication to customer satisfaction and our lightning-fast delivery services set us apart from the competition.`,
                                icon: ThumbsIcon,
                            },
                        ]}
                        classNames={{
                            base: 'mb-20',
                            section: '!w-full',
                        }}
                    />

                    <section className={`body-padding pt-[100px] pb-[73px] font-inter bg-[#FFFFF7]`}>
                        <div className="flex justify-center items-center flex-col ">
                            <div className="text-lg text-center">
                                <q>
                                    Working with Haul247 has provided great insight to how well I can organise my company’s haulage needs. I can manage, monitor
                                    and see all my trips in one place. It’s amazing to see an app that can help do both haulage and warehousing seamlessly.
                                    Haul247 also puts a premium on safety of our goods and the customer service is great.
                                </q>
                            </div>
                            <div className="mt-[30px] flex items-center gap-2 lg:gap-4 flex-col lg:flex-row">
                                <div className="w-10 h-10  grid font-bold items-center font-inter-md text-center rounded-full text-white bg-gradient-to-r from-[#f0aa22] to-[#f05822]">
                                    {_getInitials('Tosin Ajayi')}
                                </div>
                                <div className="text-center lg:text-left">
                                    <p className="font-inter-bold font-bold text-base">Tosin Ajayi</p>
                                    <p className="text-[#777777] font-medium text-xs ">GB Foods Logistics Manager</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <MarketingForm classNames=" lg:hidden" />

                    <div className="body-padding bg-[#242424] font-inter   pt-[80px] lg:sticky lg:h-screen lg:inset-0 ">
                        <h3 className="font-bold text-2xl md:text-4xl font-inter-bold  text-white">
                            Freight and Warehousing needs covered with our real-time, digital and tech- enabled logistics support.
                        </h3>

                        <div className="">
                            <img src={cargoImg} alt="" />
                        </div>
                    </div>
                </div>

                <MarketingForm inputRef={(el) => (textInput = el)} classNames="hidden lg:block fixed right-0 w-[50%] bottom-0 top-0 " />
            </div>
        </div>
    )
}

export default Marketing

import React from 'react'

const BenefitSection = (props) => {
    return (
        <section className={`body-padding pt-10 md:pt-20 font-inter ${props?.classNames?.base || ''}`}>
            <div>
                <h1 className="font-inter-md font-semibold text-[28px] leading-[38px] mb-[30px] text-[#242424]">Benefits</h1>
                <div className="grid grid-cols-[auto] md:grid-cols-[repeat(auto-fill,minmax(355px,1fr))] w-full gap-8 lg:gap-16">
                    {props.benefits.map((el, idx) => (
                        <div key={idx} className={`w-full lg:w-[355px]  ${props?.classNames?.section || ''}`}>
                            <img src={el.icon} alt="icon" />
                            <div className="mt-5 flex flex-col gap-[15px]">
                                <h4 className="text-lg text-[#242424] font-medium  ">{el.heading}</h4>
                                <p className="text-[#777777] mt-[15px] text-[15px] leading-[27px]">{el.subText}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default BenefitSection

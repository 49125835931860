import React from 'react'

const Testimonial = (props) => {
    function getInitials(name) {
        let nameArray = name.split(' ')
        let initials = nameArray[0].charAt(0) + nameArray[1].charAt(0)
        return initials
    }
    return (
        <section className={`body-padding pt-[100px] pb-[73px] ${props.backgroundColor ? props.backgroundColor : 'bg-[#FFFFF7]'}`}>
            <div className="flex justify-center items-center flex-col md:px-[75px]">
                <div className="text-lg lg:text-[26px] md:leading-[45px] text-center">
                    <q>{props.message}</q>
                </div>
                <div className="mt-[30px] flex items-center gap-2 lg:gap-4 flex-col lg:flex-row">
                    <div className="w-10 h-10 lg:w-[58px] lg:h-[58px] grid font-bold items-center font-inter-md text-center rounded-full text-white bg-gradient-to-r from-[#f0aa22] to-[#f05822]">
                        {/* <img src={props.img} alt="" /> */}
                        {getInitials(props.writer)}
                    </div>
                    <div className="text-center lg:text-left">
                        <p className="font-inter-bold font-bold text-base lg:text-lg">{props.writer}</p>
                        <p className="text-[#777777] font-medium text-sm lg:leading-[23px]">{props.role}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonial

import React from 'react'
import './footer.scss'
import ExtraHome from './extraHome'
import FooterUi from '../ui/layout/footer'

const Footer = (props) => {
    return (
        <div className="footer">
            <ExtraHome showCustomerSays={props.showCustomerSays} />

            <FooterUi />
        </div>
    )
}

export default Footer

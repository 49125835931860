import React from 'react';
import WARE from '../../assets/images/wareOwn.jpg'
import TRUCK from '../../assets/images/truckOwn.jpg'

const TabCompPartner = ({tab}) => {

    return (

        <div className="tabComponent">
            {
                tab === 'warehouse' ? (

                    <div className="service_tab_headers">
                    
                        <div className="goodContent">

                            <h1 className="h1"> Monetize your warehouses today by listing with us.</h1>

                            <p className='point'> Make your warehousing properties available on our platform and we will connect you with paying businesses that need your space.</p>

                            <div className="content_service">

                                <div className="subtitle marg">

                                <div className="points">
                                    <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                                    <p>Increased warehousing opportunities.</p>
                                </div>

                                <div className="points">

                                    <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                                    <p>Insight to asset utilization</p>

                                </div>

                                <div className="points">
                                    <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                                    <p>Increased revenue</p>
                                </div>

                                <div className="points">
                                    <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                                    <p>Automated payment settlement</p>
                                </div>  

                                <div className="points">
                                    <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                                    <p>Access to wider range of customers</p>
                                </div>  

                                <div className="points">
                                    <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                                    <p>Reliable customer support network</p>
                                </div>  

                                <div className="points">
                                    <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                                    <p>Improved brand visibility</p>
                                </div>                           

                                </div>
                                
                            </div>

                        </div>

                        <div className="image_Service">
                            <img src={WARE} alt="warehousing" />
                        </div>

                    </div>

                ) : (
                <div className="service_tab_headers">
                    
                <div className="goodContent">
    
                    <h1 className="h1"> We help you utilize your trucks by bringing the best business your way. </h1>
    
                    <p className='point'>Put your trucks to good use and earn more by partnering with us. We connect your trucks to well vetted & lisenced drivers. <strong>Don't Worry! We got you covered</strong>.</p>
    
                    <div className="content_service">
    
                        <div className="subtitle marg">
    
                        <div className="points">
                            <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                            <p>Increased revenue.</p>
                        </div>
    
                        <div className="points">
    
                            <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                            <p>Insight to asset utilization </p>
    
                        </div>
    
                        <div className="points">
                            <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                            <p>Automated payment settlement </p>
                        </div>
    
                        <div className="points">
                            <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                            <p>Instant prompts on available shipment</p>
                        </div>

                        <div className="points">
                            <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                            <p>Reliable customer support network</p>
                        </div>

                        <div className="points">
                            <div className="check_icon"><i className="fi fi-rr-check"></i></div>
                            <p>Improved brand visibility</p>
                        </div>                           
    
                        </div>
                        
                    </div>
    
                </div>
    
                <div className="image_Service">
                    <img src={TRUCK} alt="trucking" />
                </div>
    
            </div>
            )
            }
        </div>

    );
}

export default TabCompPartner;

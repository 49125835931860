import React from 'react'
import SupplyIcon from '../../assets/icons/supplyChain.svg'
import Hero from '../../components/ui/hero'
import BenefitSection from '../../components/ui/benefitSection'
import Brands from '../../components/ui/brands'
import Pages from '../../components/ui/pages'
import StatsCount from '../../components/ui/statsCount'
import TruckIcon from '../../assets/icons/benefits/trucks.svg'
import OptimizeIcon from '../../assets/icons/benefits/optimize.svg'
import BulbIcon from '../../assets/icons/benefits/bulb.svg'
import AnalysisIcon from '../../assets/icons/benefits/analysis.svg'
import PlanningIcon from '../../assets/icons/benefits/planning.svg'
import ImproveIcon from '../../assets/icons/benefits/improve.svg'
import WarehouseIcon from '../../assets/icons/warehouse.svg'
import Faq from '../../components/ui/faq'
import ContactForm from '../../components/ui/contactForm'
import Footer from '../../components/ui/layout/footer'
import Cta from '../../components/ui/cta'
import SEOTag from '../../components/seoTag'

const SupplyChain = () => {
    return (
        <>
            <SEOTag image="" title="Haul247 | Supply Chain Optimization " url="https://www.haul247.co/products/supply-chain" description="" />

            <div className="font-inter text-[#242424]">
                <Hero
                    icon={SupplyIcon}
                    heading={'Insights To Drive Your Supply Chain'}
                    subHeading={`At Haul247 we streamline and elevate your entire supply chain, driving efficiency, and unlocking opportunities for your business.`}
                    buttonText={'Discover Solutions!'}
                    link={'/contact'}
                />

                <BenefitSection
                    benefits={[
                        {
                            heading: 'Scalable Solutions',
                            subText: `Whether you’re a startup or an established enterprise, Haul247’s scalable solutions adapt to your business’s size and requirements, ensuring agility and adaptability.`,
                            icon: BulbIcon,
                        },
                        {
                            heading: 'In-Depth Analysis',
                            subText: `We dive deep into your supply chain, conducting thorough assessments to identify bottlenecks, inefficiencies, and areas ripe for improvement.`,
                            icon: AnalysisIcon,
                        },
                        {
                            heading: 'Strategic Planning',
                            subText: `Armed with data-driven insights, we craft customized strategies aimed at optimizing your supply chain, reducing costs, and enhancing overall efficiency.`,
                            icon: PlanningIcon,
                        },
                        {
                            heading: 'Continuous Improvement',
                            subText: `Our partnership doesn’t end with recommendations. We provide ongoing 24/7 support, continually refining strategies to adapt to your evolving business needs.`,
                            icon: ImproveIcon,
                        },
                        {
                            heading: 'Cost Optimization',
                            subText: `Strategies aren’t just about efficiency; they’re about cost-effectiveness. Expect savings without compromising quality or reliability.`,
                            icon: OptimizeIcon,
                        },
                    ]}
                />

                <Brands />

                <StatsCount
                    grid={'lg:grid-cols-5'}
                    textWidth={'w-[161px]'}
                    stats={[
                        { number: 30, text: `reduction in lead times`, active: false },
                        { number: 20, text: `decrease in operational costs.`, active: false },
                        { number: 40, text: `reduction in inventory holding costs`, active: true },
                        { number: 25, text: `decrease in order fulfillment costs`, active: false },
                        { number: 25, text: `decrease in order fulfillment costs`, active: false },
                    ]}
                />

                <Pages
                    pages={[
                        {
                            title: 'Supply Chain Optimization',
                            description: 'We analyze and strategize and implement tailored solutions for efficiency and cost-effectiveness.',
                            link: '/products/supply-chain',
                            icon: SupplyIcon,
                        },
                        {
                            title: 'Haulage Solutions',
                            description: `Trust us to deliver your cargo swiftly, securely, and with precision, ensuring it reaches its destination on time, every time.`,
                            link: '/products/haulage',
                            icon: TruckIcon,
                        },
                        {
                            title: 'Warehousing',
                            description:
                                'It’s not just about storing goods, it’s about maximizing every inch of space efficiently without compromising accessibility.',
                            link: '/products/warehousing',
                            icon: WarehouseIcon,
                        },
                    ]}
                />

                <div className="font-br-firma">
                    <div className="bg-[#FFFFF7]">
                        <Faq
                            faqs={[
                                {
                                    question: 'What types of storage solutions do you offer?',
                                    answer: ``,
                                },
                                {
                                    question: 'Can I access my goods stored in your warehouses?',
                                    answer: `Yes, we provide access to your goods based on agreed-upon terms and conditions.`,
                                },
                                { question: 'How do you ensure the security of stored items?', answer: `` },
                                { question: `Is there a limit on the duration of storage?`, answer: `` },
                                { question: `Can you handle inventory management?`, answer: `` },
                            ]}
                        />
                    </div>

                    <Cta
                        pillText={'READY TO GET STARTED'}
                        ctaHeading={'We are your strategic partner in transforming challenges into opportunities, inefficiencies into strengths.'}
                        ctaButton={'Unlock the potential of your supply chain'}
                        headingWidth={'lg:w-[900px]'}
                    />

                    <ContactForm />

                    <Footer />
                </div>
            </div>
        </>
    )
}

export default SupplyChain

import React from 'react'

const Hero = (props) => {
    function openContactForm() {
        /** Google Tag Manager custom event */
        window.dataLayer.push({
            event: 'click_cta',
        })

        const contactSection = document.getElementById('contact_sales')
        contactSection.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <section className="bg-[#1C4863] w-full body-padding pt-[91px] pb-[80px]">
            <div>
                <div className="bg-white rounded-full w-[58px] h-[58px] grid place-content-center">
                    <img src={props.icon} alt="truck" className="w-[30px]" width={'30px'} height={'30px'} />
                </div>
                <h2 className="font-semibold text-[26px] leading-[34px] text-white mb-[30px] mt-[18px] font-inter-md">{props.heading}</h2>
                <p className="text-base text-white w-full md:w-[724px]  mb-10">{props.subHeading}</p>

                {props.buttonText && (
                    <button onClick={openContactForm} className="btn-haul">
                        {props.buttonText}
                    </button>
                )}
            </div>
        </section>
    )
}

export default Hero

import React, { useState } from 'react'
import './services.scss'
import GB from '../../assets/images/gb.jpg'
import PZ from '../../assets/images/pz.jpg'
import BECKITT from '../../assets/images/beckitt.jpg'
import UNI from '../../assets/images/unilever.jpg'
import FB from '../../assets/images/fb.jpg'
import TabComp from './tabComp'

const Business = () => {
    const [tab, setTab] = useState('warehouse')

    const tabFunction = (e, type) => {
        document.querySelector('.current').classList.remove('current')
        e.target.classList.add('current')
        setTab(type)
    }

    return (
        <div className="services">
            <div className="landing_service fullpad">
                <div className="text_service">
                    <div className="forWho">For Businesses</div>

                    <div className="main_service_text deski">
                        Let's start {<br></br>} <span> moving </span> things {<br></br>} together today.
                    </div>

                    <div className="main_service_text mobi">
                        Let's start <span> moving </span> things together today.
                    </div>

                    <div className="sub_service_text">
                        A better solution to connecting your businesses to properly vetted and trusted truck owners, warehouse owners and truck drivers to meet
                        your haulage needs.
                    </div>

                    <a href="https://client.haul247.co/login" className="getStartedButton">
                        {' '}
                        Get Started <i className="fi fi-rr-arrow-right"></i>{' '}
                    </a>

                    <div className="trusted">
                        Trusted By
                        <div className="companies">
                            <div className="company">
                                <img src={GB} alt="haul247 companies & Partnerships" />
                            </div>

                            <div className="company">
                                <img src={UNI} alt="haul247 companies & Partnerships" />
                            </div>

                            <div className="company">
                                <img src={PZ} alt="haul247 companies & Partnerships" />
                            </div>

                            <div className="company">
                                <img src={BECKITT} alt="haul247 companies & Partnerships" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service_photo">
                    <img src={FB} alt="For Businesses" className="object-cover" />
                </div>
            </div>

            <div className="preview_services fullpad pad-3x">
                <div className="text_preview">
                    <div className="hidden">
                        <strong>Haul247, the smart way to go!</strong>
                    </div>

                    <h1>Running a business daily is already stressful, why worry about logistics?</h1>

                    <p>
                        The utmost concern of every business is to thrive by satisfying its customers' needs. That is why we have designed our products to meet
                        the numerous demands of your <strong>business below</strong> :
                    </p>

                    <div className="button-section">
                        <a href="#contact_sales" className="contact_sales outline">
                            {' '}
                            Contact Sales{' '}
                        </a>
                        <a href="https://client.haul247.co/login" className="contact_sales">
                            Haul With Us
                        </a>
                    </div>
                </div>

                <div className="lottieAnim">
                    <div className="deski">
                        <lottie-player
                            src="https://lottie.host/8a66795b-f483-4342-a8fe-ce3ca6468526/sBQZHQfM3n.json"
                            background="transparent"
                            speed="1"
                            style={{ width: '400px', height: '400px' }}
                            loop
                            autoplay
                        ></lottie-player>
                    </div>

                    <div className="mobi">
                        <lottie-player
                            src="https://lottie.host/8a66795b-f483-4342-a8fe-ce3ca6468526/sBQZHQfM3n.json"
                            background="transparent"
                            speed="1"
                            style={{ width: '250px', height: '250px' }}
                            loop
                            autoplay
                        ></lottie-player>
                    </div>
                </div>
            </div>

            <div className="core_services fullpad pad-1x">
                <div className="service_headers_tab">
                    <div className="tab current" onClick={(e) => tabFunction(e, 'warehouse')}>
                        Warehouses
                    </div>
                    <div className="tab" onClick={(e) => tabFunction(e, 'truck')}>
                        Truck Services
                    </div>
                </div>

                <div className="lineThru"></div>

                <TabComp tab={tab} />
            </div>
        </div>
    )
}

export default Business

import React, {useState} from "react";
import "./services.scss";
import GB from "../../assets/images/gb.jpg";
import PZ from "../../assets/images/pz.jpg";
import BECKITT from "../../assets/images/beckitt.jpg";
import UNI from "../../assets/images/unilever.jpg";
import FB from "../../assets/images/truckDrive.jpg";
import TabCompPartner from "./tabComp_partner";

const Partner = () => {
	const [tab, setTab] = useState("warehouse");

	const tabFunction = (e, type) => {
		document.querySelector(".current").classList.remove("current");
		e.target.classList.add("current");
		setTab(type);
	};

	return (
		<div className='services'>
			<div className='landing_service fullpad'>
				<div className='text_service'>
					<div className='forWho'>For Partners</div>

					<div className='main_service_text deski'>
						Together we can {<br></br>} <span> move </span> the supply {<br></br>} chain forward.
					</div>

					<div className='main_service_text mobi'>
						Together we can <span> moving </span> the supply chain forward.
					</div>

					<div className='sub_service_text'>
						A better solution to connecting your businesses to properly vetted and trusted truck
						owners, warehouse owners and truck drivers to meet your haulage needs.
					</div>

					<a href='https://client.haul247.co/login' className='getStartedButton'>
						{" "}
						Get Started <i className='fi fi-rr-arrow-right'></i>{" "}
					</a>

					<div className='trusted'>
						Trusted By
						<div className='companies'>
							<div className='company'>
								<img src={GB} alt='haul247 companies & Partnerships' />
							</div>

							<div className='company'>
								<img src={UNI} alt='haul247 companies & Partnerships' />
							</div>

							<div className='company'>
								<img src={PZ} alt='haul247 companies & Partnerships' />
							</div>

							<div className='company'>
								<img src={BECKITT} alt='haul247 companies & Partnerships' />
							</div>
						</div>
					</div>
				</div>

				<div className='service_photo'>
					<img src={FB} alt='For Businesses' className='object-cover' />
				</div>
			</div>

			<div className='preview_services fullpad pad-3x'>
				<div className='text_preview'>
					<div className='hidden'>
						<strong>Haul247, the smart way to go!</strong>
					</div>

					<h1>Earn better for your asset utilization.</h1>

					<p>
						As a decent and experienced driver, partnering with Haul247 opens you up to numerous
						beneficial opportunities.While at it, here are the perks you get to enjoy
					</p>

					<div className='button-section'>
						<a href='#contact_sales' className='contact_sales outline'>
							{" "}
							Contact Sales{" "}
						</a>
						<a href='https://client.haul247.co/login' className='contact_sales'>
							Haul With Us
						</a>
					</div>
				</div>

				<div className='lottieAnim'>
					<div className='deski'>
						<lottie-player
							src='https://assets10.lottiefiles.com/packages/lf20_duru2cbs.json'
							background='transparent'
							speed='1'
							style={{width: "400px", height: "400px"}}
							loop
							autoplay
						></lottie-player>
					</div>

					<div className='mobi'>
						<lottie-player
							src='https://assets10.lottiefiles.com/packages/lf20_duru2cbs.json'
							background='transparent'
							speed='1'
							style={{width: "250px", height: "250px"}}
							loop
							autoplay
						></lottie-player>
					</div>
				</div>
			</div>

			<div className='core_services fullpad pad-1x'>
				<div className='service_headers_tab'>
					<div className='tab current' onClick={(e) => tabFunction(e, "warehouse")}>
						Warehouse Owners
					</div>
					<div className='tab' onClick={(e) => tabFunction(e, "owner")}>
						Truck Owners
					</div>
				</div>

				<div className='lineThru'></div>

				<TabCompPartner tab={tab} />
			</div>
		</div>
	);
};

export default Partner;

import React from 'react'

const Faq = (props) => {
    const openFaq = (e) => {
        const target = e.target
        const getParentNode = target.parentNode.parentNode
        const faqElements = document.querySelectorAll('.faq')

        for (let faq of faqElements) {
            if (faq === getParentNode) {
                faq.classList.toggle('openFaq')
            } else {
                faq.classList.remove('openFaq')
            }
        }
    }

    return (
        <div className="faqs body-padding pt-10 py-[70px]">
            <h2 className="forWho tiny-name">Frequently Asked Questions</h2>

            <div className="mainText">Got questions? find answers here</div>

            <div className="faqSection">
                {props.faqs.map((faq, idx) => (
                    <div className="faq" key={idx}>
                        <div className="text-faq ">
                            <h3 className="q cursor-pointer mr-10" onClick={(e) => openFaq(e)}>
                                {faq.question}
                            </h3>

                            <div className="a">{faq.answer}</div>
                        </div>

                        <div className="controller" onClick={(e) => openFaq(e)}>
                            <i className="fi fi-rr-add"> </i>
                        </div>

                        <div className="controller controllerB" onClick={(e) => openFaq(e)}>
                            <i className="fi fi-rr-minus"> </i>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Faq

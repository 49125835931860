import React, { useEffect, useState } from 'react'
import ContentfulClient from '../../../utils/contentfull/contentfull'
import './blogs.scss'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'
import PostCard from './components/postCard'
import PostCardSkeleton from './components/postCardSkeleton'

const contentful = new ContentfulClient('blog')
const Blogs = () => {
    const [blogPosts, setBlogPosts] = useState([])
    const [loading, setLoading] = useState(true)
    const [subscribeText, setSubscribeText] = useState('')
    const [userName, setUserName] = useState('')
    const [tags, seTtags] = useState([])
    const [activeTag, setActiveTag] = useState('')

    useEffect(() => {
        fetchData()
        getBlogTags()
    }, [])

    const fetchData = async (tag = '', flag = 1) => {
        if (flag === 2) {
            setLoading(true)
        }
        const blogs = await contentful.getEntries(tag)
        if (blogs) {
            setBlogPosts(blogs)
        }
        setLoading(false)
    }

    const getBlogTags = async () => {
        const tags = await contentful.getTags()
        if (tags) {
            seTtags([{ name: 'All', id: '' }, ...tags])
        }
    }

    function handleSubscription(e) {
        e.preventDefault()
        console.log('subscribed')
    }

    return (
        <>
            <div className="font-inter text-[#242424] h-full mb-28">
                <section id="hero" className="body-padding py-10 md:pt-[117px] md:pb-[69px]  text-white">
                    <div>
                        <h1 className="font-bold text-3xl md:text-[45px] md:leading-[50px] font-inter-md">Get Informed</h1>
                        <div className="mt-[30px]">
                            <h6>Here’s the update center for Haul247</h6>

                            <form className="my-4 flex flex-col md:flex-row w-full  gap-3" onSubmit={handleSubscription}>
                                <div className="rounded-md bg-white py-4 px-4 md:px-8 md:w-[500px] flex">
                                    <input
                                        value={subscribeText}
                                        required
                                        onChange={(e) => setSubscribeText(e.target.value)}
                                        type="email"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                        placeholder="Enter email address"
                                        className="border-none focus:outline-none focus:border-none w-full h-full text-black text-base md:text-sm"
                                    />

                                    <div className="bg-[#777777] w-1 h-auto opacity-65 mx-4"></div>
                                    <input
                                        value={userName}
                                        required
                                        onChange={(e) => setUserName(e.target.value)}
                                        type="text"
                                        placeholder="Enter first name"
                                        className="border-none focus:outline-none focus:border-none w-full h-full text-black text-base md:text-sm"
                                    />
                                </div>
                                <button
                                    onClick={() => null}
                                    className="text-sm rounded-[5px] px-[26px] py-[13px] text-white bg-gradient-to-r from-[#f0aa22] to-[#f05822]  font-semibold"
                                >
                                    Subscribe
                                </button>
                            </form>

                            <p className="text-[13px] leading-[18px] w-full max-w-[466px]">
                                Subscribe now and stay informed and updated. Your email and first name will help us keep you updated. We promise not to spam
                                you, and you can unsubscribe at any time
                            </p>
                        </div>
                    </div>
                </section>
                <section className="bg-[#F8F8F8] body-padding h-12">
                    <div className="flex items-center h-full w-full gap-6  sm:gap-14 overflow-auto  scroll-smooth snap-mandatory snap-x" id="tag">
                        {tags.map((tag, idx) => (
                            <button
                                key={idx}
                                onClick={() => {
                                    fetchData(tag.id, 2)
                                    setActiveTag(tag.id)
                                }}
                                className={`text-[#242424] text-sm scroll-m-0 font-semibold whitespace-nowrap snap-start transition ease-in duration-200  ${activeTag === tag.id ? 'border p-2 border-black' : ''}`}
                            >
                                {tag.name}
                            </button>
                        ))}
                    </div>
                </section>

                <section id="blog-post" className="body-padding mt-20">
                    <input
                        value={subscribeText}
                        onChange={(e) => setSubscribeText(e.target.value)}
                        type="text"
                        // className="border-none focus:outline-none focus:border-none w-full h-full"
                    />
                    <div className="grid sm:grid-cols-[repeat(auto-fill,minmax(386px,1fr))] gap-7">
                        {loading
                            ? Array.from({ length: 3 }).map((_, idx) => <PostCardSkeleton key={idx} />)
                            : blogPosts.length
                              ? blogPosts.map((blog, idx) => <PostCard baseUrl={'/resources/blog/'} key={idx} post={blog} />)
                              : null}
                    </div>
                </section>
            </div>
            <div className="font-br-firma">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default Blogs
